// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/loader.gif");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".checkout-loader {\n    background: rgba(255, 255, 255, 0.5);\n    position: fixed;\n    width: 100%;\n    left: 0;\n    height: 100%;\n    top: 0;\n    z-index: 9999;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.checkout-loader span {\n    background: no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    display: inline-block;\n    width: 50px;\n    height: 50px;\n    background-size: cover;\n}", "",{"version":3,"sources":["index.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,WAAW;IACX,OAAO;IACP,YAAY;IACZ,MAAM;IACN,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,6DAAoD;IACpD,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B","file":"index.css","sourcesContent":[".checkout-loader {\n    background: rgba(255, 255, 255, 0.5);\n    position: fixed;\n    width: 100%;\n    left: 0;\n    height: 100%;\n    top: 0;\n    z-index: 9999;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.checkout-loader span {\n    background: no-repeat url('../../assets/loader.gif');\n    display: inline-block;\n    width: 50px;\n    height: 50px;\n    background-size: cover;\n}"]}]);
// Exports
module.exports = exports;
