import React, { useEffect, useState } from 'react';
import './redirect-styles.css';  // Import the CSS file

const RedirectToNativeApp = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for mobile devices
    if (/android|iphone|ipad|ipod/i.test(userAgent)) {
      setShowPrompt(true);
    }
  }, []);


  const handleOpenApp = () => {
    const iosAppStoreURL = 'https://apps.apple.com/us/app/jivebird/id946644356';
    let link;

    if (/android/i.test(navigator.userAgent)) {
      // Android intent with custom scheme and package name
      link = "intent://jivebirdhost#Intent;scheme=jivebirdsch;package=com.greetings.jivebird;end;";
    } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      // iOS custom URL scheme
      // link = "jivebirdios://";

      // Fallback to App Store after 2 seconds if the app wasn't opened
      // setTimeout(() => {
        window.location.href = iosAppStoreURL;
      // }, 2000);
    } else {
      // Fallback to the website for non-mobile devices
      link = 'https://www.web.jivebird.com/';
    }

    // Redirect after a short delay to allow handling
    setTimeout(() => {
      window.location.href = link;
    }, 500);
  };


  const handleStayOnWeb = () => {
    // Directly redirect to the website
    window.location.href = 'https://www.jivebird.com';
  };

  if (!showPrompt) return null;

  return (
    <>
      <div className="redirect-overlay" />
      <div className="redirect-container">
        <h2 className="redirect-title">Open JiveBird App</h2>
        <p className="redirect-message">
          We noticed you are on a mobile. For a better experience, please use the mobile app.
        </p>
        <div className="redirect-button-container">
          <button className="redirect-primary-button" onClick={handleOpenApp}>
            Go to App
          </button>
          <button className="redirect-secondary-button" onClick={handleStayOnWeb}>
            Go to Website
          </button>
        </div>
      </div>
    </>
  );
};

export default RedirectToNativeApp;
