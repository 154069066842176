import React from 'react';
import './index.css';

// Loader Component for all the pages
const Loader = (props) => {
  return (
    <>
      {props.showLoader === true ? (
        <div className="checkout-loader">
          <span></span>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
